const strings = {
    with: {
        en: 'with',
        es: 'con',
    },
    search_guide_air: {
        en: 'Enter flight details to find your receipt',
        es: 'Ingrese sus detalles de vuelo para buscar su recibo',
    },
    search_guide_rail: {
        en: 'Enter journey details to find your receipt',
        es: 'Ingrese sus detalles de viaje para buscar su recibo',
    },
    search_payment_method: {
        en: 'Payment method',
        es: 'Método de pago',
    },
    search_payment_method_card: {
        en: 'Card/Contactless',
        es: 'Tarjeta/Contactless',
    },
    search_payment_method_mobile: {
        en: 'Mobile',
        es: 'Móvil',
    },
    search_payment_method_maestro: {
        en: 'Maestro',
        es: 'Maestro',
    },
    search_departure_date: {
        en: 'Departure Date',
        es: 'Fecha de Ida',
    },
    search_last_4_digits: {
        en: 'Card Number (Last 4 digits)',
        es: 'Numero de Tarjeta (últimos 4 dígitos)',
    },
    search_journey_number_air: {
        en: 'Flight Number',
        es: 'Numero de Vuelo',
    },
    search_journey_number_rail: {
        en: 'Train number',
        es: 'Numero de ruta',
    },
    search_seat_number: {
        en: 'Seat Number',
        es: 'Numero de Asiento',
    },
    btn_find_my_receipts: {
        en: 'Find my receipts',
        es: 'Buscar mis recibos',
    },
    search_result_found: {
        en: (n) => `You have ${n} receipt${n > 1 ? 's' : ''}`,
        es: (n) => `Tienes ${n} recibo${n > 1 ? 's' : ''}`,
    },
    search_result_nothing_air: {
        en: 'We could not find a receipt with those flight details. Please try again.',
        es: 'No pudimos encontrar su recibo con esa información de vuelo. Por favor intenta de nuevo.',
    },
    search_result_nothing_rail: {
        en: 'We could not find a receipt with those journey details. Please try again.',
        es: 'No pudimos encontrar su recibo con esa información de viaje. Por favor intenta de nuevo.',
    },
    search_result_nothing_contact_support_air: {
        en: (email) => `We could not find a receipt with those flight details. Please contact <a href="mailto:${email}">${email}</a> to find your receipt.`,
        es: (email) => `No pudimos encontrar su recibo con esa información de vuelo. Por favor póngase en contacto con <a href="mailto:${email}">${email}</a> para encontrar su recibo.`,
    },
    search_result_nothing_contact_support_rail: {
        en: (email) => `We could not find a receipt with those journey details. Please contact <a href="mailto:${email}">${email}</a> to find your receipt.`,
        es: (email) => `No pudimos encontrar su recibo con esa información de viaje. Por favor póngase en contacto con <a href="mailto:${email}">${email}</a> para encontrar su recibo.`,
    },
    disclaimer_air: {
        en: '*Your receipt will be available 24 hours after your flight.',
        es: '*Su recibo estará disponible 24 horas después de su vuelo.',
    },
    disclaimer_rail: {
        en: '*If you have trouble downloading your payment receipt please contact our customer service, click <a href="https://ouigo.zendesk.com/hc/es/requests/new">here</a>.',
        es: '*Si tienes problemas para descargar tu recibo o  deseas factura de caje puedes dirigirte a nuestro servicio de atención al cliente, pincha <a href="https://ouigo.zendesk.com/hc/es/requests/new">aquí</a>.',
    },
    footer: {
        en: (name) => `A solution by ${name} and AirFi.aero`,
        es: (name) => `Una solución por ${name} y AirFi.aero`,
    },
    receipt_type_payment: {
        en: 'Payment',
        es: 'Tipo de Pago',
    },
    receipt_type_refund: {
        en: 'Refund',
        es: 'Reembolso',
    },
    receipt_journey_number_air: {
        en: 'Flight number',
        es: 'Numero de Vuelo',
    },
    receipt_journey_number_rail: {
        en: 'Train number',
        es: 'Numero de ruta',
    },
    receipt_time: {
        en: 'Time',
        es: 'Hora',
    },
    receipt_amount: {
        en: 'Amount',
        es: 'Cantidad',
    },
    btn_download_pdf: {
        en: '⬇ Download PDF',
        es: '⬇ Descargar PDF',
    },
    btn_go_back: {
        en: '⬅ Go back',
        es: '⬅ Regresar',
    },
    receipt_pdf_refund: {
        en: 'Refund Invoice',
        es: 'Factura de Reembolso',
    },
    receipt_pdf_invoice: {
        en: 'Invoice',
        es: 'Factura',
    },
    receipt_pdf_id: {
        en: 'Purchase ID',
        es: 'Número de Compra',
    },
    receipt_pdf_number: {
        en: 'Number',
        es: 'Número',
    },
    receipt_pdf_tablet_id: {
        en: 'Serial',
        es: 'Serie',
    },
    receipt_pdf_simplified_invoice: {
        en: 'Simplified Invoice',
        es: 'Factura Simplificada',
    },
    receipt_pdf_date: {
        en: 'Date',
        es: 'Fecha',
    },
    receipt_pdf_journey_air: {
        en: 'Flight',
        es: 'Vuelo',
    },
    receipt_pdf_journey_rail: {
        en: 'Train number',
        es: 'Numero de ruta',
    },
    receipt_pdf_description: {
        en: 'Description',
        es: 'Descripción',
    },
    receipt_pdf_price: {
        en: 'Price',
        es: 'Precio',
    },
    receipt_pdf_tax_rate: {
        en: 'Tax Rate',
        es: 'Tasa de Impuesto',
    },
    receipt_pdf_tax_added: {
        en: 'Tax Added',
        es: 'Impuesto Agregado',
    },
    receipt_pdf_quantity: {
        en: 'Quantity',
        es: 'Cantidad',
    },
    receipt_pdf_amount: {
        en: 'Amount',
        es: 'Total',
    },
    receipt_pdf_total: {
        en: 'Total (tax included)',
        es: 'Total (impuesto incluido)',
    },
    receipt_pdf_total_tax: {
        en: 'Total tax',
        es: 'Total Impuesto',
    },
    receipt_pdf_generated_footer: {
        en: (date) => `Generated on ${date}`,
        es: (date)=> `Generada en ${date}`,
    },
    receipt_pdf_issued_by: {
        en: 'Issued by',
        es: 'Denominación',
    },
    receipt_pdf_address: {
        en: 'Address',
        es: 'Dirección',
    },
    receipt_discount: {
        en: 'DISCOUNT',
        es: 'DESCUENTO',
    },
};

function translate(string, language) {
    let lang = language || 'en';
    return strings[string][lang] || strings[string]['en'];
}

function translateTemplate(string, args, language) {
    let lang = language || 'en';
    return strings[string][lang] ? strings[string][lang](...args) : strings[string]['en'](...args);
}

module.exports = {
    translate,
    translateTemplate,
};